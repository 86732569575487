import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import GoldSpinner from './GoldSpinner';
import '../styles/IndexPage.css';
import bankingService from '../images/bankingService.jpg';
import loanImage from '../images/loanImage.jpg';
import banner from '../images/taisang email banner.jpg'
import handcashbag from '../images/handcashbag.jpeg';
import house from '../images/house.jpeg';
import aboutUsImage from '../images/AboutUsImage.jpg';
import bannerImg1 from '../images/bannerImg.jpg';
import safeDepo from '../images/safeDepo.jpg';
import office from '../images/office.jpg';
import office1 from '../images/office1.jpg';
import logo from '../images/logo.png';
import bannerImg2 from '../images/bannerEn.jpg';

import rental from '../images/rental.png';
import menuIcon from '../images/menuIcon.png';
import TopNav from './TopNav';
import Footer from './Footer';

// Custom hook to preload images
const useImagePreloader = (imageUrls) => {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const preloadImages = async () => {
      const promises = imageUrls.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      await Promise.all(promises);
      if (isMounted) {
        setImagesPreloaded(true);
      }
    };

    preloadImages();

    return () => {
      isMounted = false;
    };
  }, [imageUrls]);

  return imagesPreloaded;
};

const IndexPage = () => {
  const { t, i18n } = useTranslation();
  const bannerImages = [bannerImg1, office1, office, bannerImg2];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setIsOpen(false);
  };
  // Preload banner images
  const imagesPreloaded = useImagePreloader(bannerImages);
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  useEffect(() => {
    if (imagesPreloaded) {
      const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
      }, 3000); // Change image every 3 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [imagesPreloaded, bannerImages.length]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const navigateToHome = () => {
    navigate('/');
  };

  const navigateToDeposit = () => {
    navigate('/deposit/services');
  }

  const navigateToRental = () => {
    navigate('/rental/services')
  }

  const navigateToLoan = () => {
    navigate('/bank/services');
  }

  const navigateToLogin = () => {
    navigate('/login');
  }

  const navigateToAboutUs = () => {
    navigate('/aboutUs')
  }

  const handleOnlineBanking = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

  // if (!imagesPreloaded) {
  //   return <div>Loading...</div>; // or a loading spinner
  // }
  if (!imagesPreloaded) {
    return <GoldSpinner />;
  }


  return (
    <div className='mainContainer'>
      <br />
      {/* <div>

      <button onClick={() => changeLanguage('en')}>English</button>
      <button onClick={() => changeLanguage('zh')}>中文</button>
    </div> */}
      {/* <div className='topNav'>
        <img onClick={navigateToHome} id='logo' src={logo} alt='logo' />
        <div className='linksDiv'>
          <a href='/'>{t('home')}</a>
          <a href='/aboutUs'>{t('aboutUs')}</a>
          <a href='/bank/services'>{t('bankServices')}</a>
          <a href='/useful/information'>{t('usefulInformation')}</a>
          <a href='/contactUs'>{t('contactUs')}</a>
          <a href='/signup'>{t('signUp')}</a>
          <div className="languageDropdown">
          <button
            className="languageButton"
            onClick={() => setIsOpen(!isOpen)}
          >
            {i18n.language === 'en' ? 'English' : '中文'}
            <span className="dropdownArrow">▼</span>
          </button>
          {isOpen && (
            <div className="dropdownMenu">
              <button onClick={() => changeLanguage('en')}>English</button>
              <button onClick={() => changeLanguage('zh')}>中文</button>
            </div>
          )}
        </div>
        </div>
       
        <button onClick={handleOnlineBanking} id='online'>{t('onlineBanking')}</button>
        <img id='menuIcon' src={menuIcon} alt='Menu Icon' onClick={toggleMenu} />
      </div> */}
      <TopNav />


      {isMenuOpen && (
        <div className='mobileMenu'>
          <a href='/'>{t('home')}</a>
          <a href='/aboutUs'>{t('aboutUs')}</a>
          <a href='/bank/services'>{t('bankServices')}</a>
          <a href='/contactUs'>{t('contactUs')}</a>
        </div>
      )}
      <br />
      <div className='BannerDiv'>
        <div
          className='BannerBackground'
          style={{
            backgroundImage: `url(${bannerImages[currentImageIndex]})`,
            opacity: '0.5', // 50% opacity
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <div className='overlay'>
          <div className='text'>
            <h1 className='indexH1'>
              {t('welcomeTo')} <br /> {t('taiSangBank')}
            </h1>
            <br />
            <p>
              {t('empowerYourFinances')}
            </p>
            <br />
            <br />
            <br />
            <div className='servicesDivs'>
              <div className='loan' onClick={navigateToLoan}>
                <img id='loanImage' src={loanImage} alt='Loan Services' />
                <h5>{t('loanServices')}</h5>
                <span>
                  {t('flexibleLoans')}
                </span>
              </div>
              <div className='rental' onClick={navigateToRental}>
                <img id='loanImage' src={house} alt='Rental Services' />
                <h5>{t('rentalServices')}</h5>
                <span>{t('efficientRentalMgt')}</span>
              </div>
              <div className='deposit' onClick={navigateToDeposit}>
                <img id='loanImage' src={handcashbag} alt='Deposit Services' />
                <h5>{t('depositServices')}</h5>
                <span>{t('secureConvenientDeposits')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='infoDiv'>
        <br />
        <div className='bankingService'>
          <div className='imageDiv'><br />
            <img id='aboutUsImage' onClick={navigateToLoan} src={bankingService} alt='About Us' />
          </div>
          <div className='textDiv'>
            <span>
              <h1 className='indexH1'>{t('bankingService')}</h1>
              {t('bankingServiceDesc')}
            </span>
          </div>
        </div><br />
        <div className='aboutUs'>
          <div className='imageDiv'><br />
            <img id='aboutUsImage' onClick={navigateToDeposit} src={safeDepo} alt='About Us' />
          </div>
          <div className='textDiv'>
            <span>
              <h1 className='indexH1'>{t('safeDeposit')}</h1>
              {t('safeDepositDesc')}
            </span>
          </div>
        </div>
        <div className='bankingService'>
          <div className='imageDiv'><br />
            <img id='aboutUsImage' src={rental} onClick={navigateToRental} alt='About Us' />
          </div>
          <div className='textDiv'>
            <span>
              <h1 className='indexH1'>{t('rentalPropertyManagement')}</h1>
              {t('rentalPropertyManagementDesc')}
            </span>
          </div><br />
        </div>
        <div className='aboutUs'>
          <div className='imageDiv'><br />
            <img id='aboutUsImage' src={aboutUsImage} onClick={navigateToAboutUs} alt='About Us' />
          </div>
          <div className='textDiv'>
            <span>
              <h1 className='indexH1'>{t('aboutUs')}</h1>
              {t('aboutUsDesc')}
            </span>
          </div>
        </div>
      </div><br /><br />
      <Footer/>
      {/* <div className='bottomDiv'>
        <div className='topBottomDiv'>
          <div className='leftSide'>
            <img className='btDivLogo' onClick={navigateToHome} style={{ backgroundColor: 'white', padding: '10px' }} id='logo' src={logo} alt='logo' />
            <br />
            <span>2024 Tai Sang - {t('allRightsReserved')}</span>
          </div>
          <div className='rightSide'>
            <h4>{t('contactUs')}</h4>
            <span>{t('contactUsAddress')} <br /> {t('contactUsEmail')}</span>
            <br />
            <h5>{t('contactUsPhoneHK')}</h5>
            <span>{t('contactUsPhoneNonHK')}</span>
          </div>
        </div>
        <br />
      </div> */}
    </div>
  );
};

export default IndexPage;

 
