import { useTranslation } from 'react-i18next';
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/AboutUs.css';
import bannerImg from '../images/aboutBanner.jpg';
import menuIcon from '../images/menuIcon.png';

import logo from '../images/logo.png'
import linkedIn from '../images/linkedIn.svg';
import x from '../images/X.svg';
import consolidation from '../images/consolidation.png';
import consolidation1 from '../images/consolidation.png';
import financial1Pdf from '../pdf/financial1.pdf';
import financial2Pdf from '../pdf/financial2.pdf';
import TopNav from './TopNav';
import Footer from './Footer';


const AboutUs = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeContent, setActiveContent] = useState('historyOfBank');
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogin = () => {
    navigate('/login');
  };
  const navigateToHome = () => {
    navigate('/');
  }; 
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setIsOpen(false);
  };
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);
  const handleOnlineBanking = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };
  const renderContent = () => {
    switch (activeContent) {
      case 'historyOfBank':
        return (
          <div className='historyOfBank'>
            {t('Tai Sang Ngan Ho was founded by Mr. Ma Kam Chan in 1937. It was changed to Tai Sang Bank Limited (大生銀號有限公司) on 24 February 1954 and finally to Tai Sang Bank Limited (大生銀行有限公司) on 21 February 1961. Tai Sang Bank Limited is a licensed bank privately owned by the Ma Family in Hong Kong.')}
          </div>
        );
      case 'boardOfDirectors':
        return (
          <div className='boardOfDirectorsMain'>
            <div className='boardOfDirectors'>
              <div className='boardHeader'>{t('Board Of Directors')}</div>
              <div className='boardBody'>
                <div className='boardTitle'><br />
                  <span>{t('Chairman')}</span><br /><br /><br />
                  <span>{t('Chief Executive & General Manager')}</span><br /><br />
                  <span>{t('Independent Non-executive Director')}</span><br /><br /><br /><br /><br />
                  <span>{t('Alternate Director')}</span><br /><br /><br />
                  <span>{t('Other Board Members')}</span><br />
                </div>
                <div className='boardContent'><br />
                  <span>{t('MA CHING HANG, PATRICK')}</span><br /><br /><br />
                  <span>{t('MA CHING KUEN, ALFRED')}</span><br /><br /><br />
                  <span>{t('YU MAN WOON')}</span><br />
                  <span>{t('TAN LENG CHENG, AARON')}</span><br />
                  <span>{t('CHUNG KONG FEI, STEPHEN')}</span><br /><br /><br />
                  <span>{t('MA CHING MAN, KATY')}</span><br />
                  <span>{t('MA CHING SAU, AMY')}</span><br /><br />
                  <span>{t('MA CHING TSUN')}</span><br />
                  <span>{t('MA CHING YUK')}</span><br />
                  <span>{t('MA CHING YEUNG, PHILIP')}</span><br />
                  <span>{t('MA CHING CHENG')}</span>
                </div>
                <div className='SBJ'><br />
                  <span>{t('BBS, J.P.')}</span><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                  <span>{t('SBS, J.P.')}</span>
                </div>
              </div>
            </div><br />
            <div className='management'>
              <div className='managementHeader'>{t('Management')}</div>
              <div className='boardBody'>
                <div className='boardTitle'><br />
                  <span>{t('Alternate Chief Executive & Deputy General Manager')}</span><br /><br />
                  <span>{t('Other Managers')}</span>
                </div>
                <div className='boardContent'><br />
                  <span>{t('CHEUNG YUEN KWAN, DOROTHY')}</span><br /><br /><br />
                  <span>{t('MA CHING YUK')}</span>
                </div>
              </div>
            </div>
          </div>
        );
      case 'PressRelease':
        return (
          <div className='PressRelease'>
            {t('Press Release content.')}
          </div>
        );
      case 'career':
        return (
          <div className='career'>
            {t('No position available')}
          </div>
        );
      case 'financialHighlights':
        return (
          <div className='financialHighlights'>
            <div className='consolidation'>
              <a href={financial1Pdf} target="_blank" rel="noopener noreferrer">
                <img src={consolidation} alt="" /><br />
                <span>{t('Consolidated statement of comprehensive income')}</span>
              </a>
            </div><br /><br /><br />
            <div className='consolidation'>
              <a href={financial2Pdf} target="_blank" rel="noopener noreferrer">
                <img src={consolidation1} alt="" /><br />
                <span>{t('Consolidated statement of financial position')}</span>
              </a>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  
  return (
    <div className='aboutUsCont'>
      <br />
    
  <TopNav /> 
      <br />
      <div className='banner'>
        <img id='bannerImg' src={bannerImg} alt='' />
      </div><br />
      <div className='clickableItems'>
        <span
          onClick={() => setActiveContent('historyOfBank')}
          className={activeContent === 'historyOfBank' ? 'active' : ''}
        >
          {t('History of Bank')}
        </span>
        <span
          onClick={() => setActiveContent('boardOfDirectors')}
          className={activeContent === 'boardOfDirectors' ? 'active' : ''}
        >
          {t('Board of Directors & Management')}
        </span><br /><br />
        <span
          onClick={() => setActiveContent('financialHighlights')}
          className={activeContent === 'financialHighlights' ? 'active' : ''}
        >
          {t('Financial Highlights')}
        </span><br /><br />
        <span
          onClick={() => setActiveContent('PressRelease')}
          className={activeContent === 'PressRelease' ? 'active' : ''}
        >
          {t('Press Release/Other Announcements')}
        </span>
        <span
          onClick={() => setActiveContent('career')}
          className={activeContent === 'career' ? 'active' : ''}
        >
          {t('Career')}
        </span>
        <br />
        <br />
      </div>
      <div className='content'>{renderContent()}</div><br /><br />
   
    </div>
  );
  
 };

export default AboutUs;
