import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/ContactUs.css';
import bannerImg from '../images/usefulInfo.png';
import complaintPDF from '../images/Complaint_-_notice_to_customer_20240328.pdf';
import scheduleCharges from '../images/ScheduleCharges.png'
import foreign from '../images/Foreign.png'
import STCS from '../images/STCS.png'
import GTAC from '../images/GTAC.png'
import NoticeC from '../images/NoticeC.png'
import privacyPdf from '../pdf/privacy.pdf'
import noticePdf from '../pdf/notice.pdf'
import generalTcEng from '../pdf/general_tc_Eng.pdf';
import suppEng from '../pdf/supp_Eng.pdf';
import info1Pdf from '../pdf/info1.pdf';
import info2Pdf from '../pdf/info2.pdf';
import TopNav from './TopNav';

const UsefulInfo = () => {
    const [activeContent, setActiveContent] = useState('FeeSchedules');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    const handleComplaintClick = () => {
        window.open(complaintPDF, '_blank');
    };

    const renderContent = () => {
        switch (activeContent) {
            case 'FeeSchedules':
                return (
                    <div className='FeeSchedules'>
                        <br /><br />
                        <div className='feeChInfo'>
                            <div>
                                <a href={info1Pdf} target="_blank" rel="noopener noreferrer">
                                    <img src={scheduleCharges} alt="" />
                                </a>
                                <p>{t('Schedule of Charges')}</p>
                            </div>
                            <div>
                                <a href={info2Pdf} target="_blank" rel="noopener noreferrer">
                                    <img src={foreign} alt="" />
                                </a>
                                <p>{t('Service charges relating to Foreign Currency Transactions')}</p>
                            </div>
                        </div>
                    </div>
                );
            case 'StandardInterestRates':
                return (
                    <div className="directorsBankingServices">
                        <div className="headerba" id='bsheader'>
                            <h2>{t('Standard Interest Rates')}</h2>
                        </div>
                        <br />
                        <div className="bodyba" id='bsheader'>
                            <span>{t('Tai Sang Bank Best Lending Rate: 6.000% p.a.')}</span><br /><br />
                            <span>{t('Tai Sang Bank HKD Savings Deposit Rate: 0.875% p.a.')}</span><br /><br />
                            <span>{t('Tai Sang Bank RMB Savings Deposit Rate: 0.25% p.a.')}</span><br /><br />
                            <span>{t('Effective since 28-July-2023')}</span><br /><br />
                        </div><br /><br />
                    </div>
                );
            case 'GeneralTermsAndConditions':
                return (
                    <div className='FeeSchedules'>
                        <br /><br />
                        <div className='feeChInfo'>
                            <div>
                                <a href={generalTcEng} target="_blank" rel="noopener noreferrer">
                                    <img src={GTAC} alt="" />
                                </a>
                                <span>{t('General Terms And Conditions')}</span>
                            </div>
                            <div>
                                <a href={suppEng} target="_blank" rel="noopener noreferrer">
                                    <img src={STCS} alt="" />
                                </a>
                                <p>{t('Supplementary Terms & Conditions For Saving Accounts, Current Accounts And Fixed Deposit Accounts')}</p>
                            </div>
                        </div>
                    </div>
                );
            case 'PrivacyPolicyStatement':
                return (
                    <div className='FeeSchedules'>
                        <br /><br />
                        <div className='feeCh'>
                            <div id='feechImg'>
                                <a href={privacyPdf} target="_blank" rel="noopener noreferrer">
                                    <img src={GTAC} alt="" /><br />
                                </a>
                                <p>{t('Privacy Policy Statement')}</p>
                            </div>
                        </div>
                    </div>
                );
            case 'PersonalInformation':
                return (
                    <div className='FeeSchedules'>
                        <br /><br />
                        <div className='feeChinfo'>
                            <div id='feechImg'>
                                <a href={noticePdf} target="_blank" rel="noopener noreferrer">
                                    <img src={NoticeC} alt="" /><br />
                                </a>
                                <span>
                                    {t('Notice to Customers and Other Individuals relating to the Personal Data (Privacy) Ordinance')}
                                </span>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    }

    return (
        <div className="contactMainCont">
            <br />
            <TopNav />
            <br />
            <div className='banner'>
                <img id='bannerImg' src={bannerImg} alt='' />
                <div className="banner-content">
                    <div className="container-vm">
                        <div className="container-vm-box">
                            <div className="container">
                                <div className="banner-title">{t('Useful Information')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className='clickableItems'>
                <span
                    onClick={() => setActiveContent('FeeSchedules')}
                    className={activeContent === 'FeeSchedules' ? 'active' : ''}
                >
                    {t('Fee Schedules')}
                </span>
                <span
                    onClick={() => setActiveContent('StandardInterestRates')}
                    className={activeContent === 'StandardInterestRates' ? 'active' : ''}
                >
                    {t('Standard Interest Rates')}
                </span><br /><br />
                <span
                    onClick={() => setActiveContent('GeneralTermsAndConditions')}
                    className={activeContent === 'GeneralTermsAndConditions' ? 'active' : ''}
                >
                    {t('General Terms And Conditions')}
                </span>
                <span
                    onClick={() => setActiveContent('PrivacyPolicyStatement')}
                    className={activeContent === 'PrivacyPolicyStatement' ? 'active' : ''}
                >
                    {t('Privacy Policy Statement')}
                </span><br /><br />
                <span
                    onClick={() => setActiveContent('PersonalInformation')}
                    className={activeContent === 'PersonalInformation' ? 'active' : ''}
                >
                    {t('Personal Information Collection Statement ("PICS")')}
                </span>
                <br />
                <br />
            </div>
            <div className='content'>{renderContent()}</div><br /><br />
        </div>
    );
}

export default UsefulInfo;