import GoldSpinner from './GoldSpinner';
import authMiddleware from './authMiddleware';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/dashboard.css';
import { FaCopy } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import NotificationDropdown from './Notifications';
import TopNav from './TopNav';
import Sidebar from './SideBar';
import { useCurrencyFormatter } from './currencyFormatter';

const CURRENCY_MAP = {
  USD: { symbol: '$', name: 'US Dollar' },
  EUR: { symbol: '€', name: 'Euro' },
  GBP: { symbol: '£', name: 'British Pound' },
  HKD: { symbol: 'HK$', name: 'Hong Kong Dollar' },
};

function Dashboard() {
  const { t, i18n } = useTranslation();
  const [copySuccess, setCopySuccess] = useState('');
  const [balance, setBalance] = useState('');
  const [volume, setVolume] = useState('');
  const [user, setUser] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { formatCurrency, error: currencyError, refetchUserData } = useCurrencyFormatter();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    setUser(userData);
  }, []);
  // New function to format numbers with commas
  const formatNumberWithCommas = (number) => {
    if (isNaN(number) || number === null || number === undefined) {
      return '';
    }
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatCurrencyWithCommas = (amount) => {
    if (isNaN(amount) || amount === null || amount === undefined) {
      return `${getCurrencySymbol()}0.00`;
    }
    const formattedAmount = formatCurrency(amount);
    const [currency, number] = formattedAmount.split(' ');
    return `${currency} ${formatNumberWithCommas(parseFloat(number))}`;
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${global.baseUrl}users/transactions`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message || t('failedToFetchTransactions'));
        }
        setTransactions(data.transactions);
        setBalance(parseFloat(data.balance) || 0);
        setVolume(parseFloat(data.transactionVolume) || 0);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError(error.message || t('errorFetchingTransactions'));
      }
    };

    if (user) {
      fetchTransactions();
    }
  }, [user, t]);
  

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess(t('copied'));
      setTimeout(() => {
        setCopySuccess('');
      }, 2000);
    });
  };

  const navigateToSettings = () => navigate('/settings');
  const navigateToTransfer = () => navigate('/transfer');
  const navigateToDeposit = () => navigate('/deposit');

  const getCurrencySymbol = () => {
    if (!user || !user.accountCurrency || !CURRENCY_MAP[user.accountCurrency]) {
      return '';
    }

    return CURRENCY_MAP[user.accountCurrency].symbol;
  };

  
  if (!user) {
    return <GoldSpinner />;
  }
  if (currencyError) {
    return <div>Error: {currencyError}. <button onClick={refetchUserData}>Retry</button></div>;
  }

  let names = `${user.firstName} ${user.lastName}`;
  const currencySymbol = getCurrencySymbol();

  return (
    <div className="dashboard">
      <div className="icons-container">
        <NotificationDropdown />
        <div onClick={navigateToSettings} className="profile-icon">
          <svg className='profileSvg' viewBox="0 0 30 30" fill="currentColor" width="40" height="40">
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
          </svg>
        </div>
      </div>

      <header className="dashboard-header">
        <div className="greeting">
          <h2>{t('hello')} {names},</h2>
          <p>{t('whatWouldYouLikeToDo')}</p>
        </div>
        <div className="actions">
          <button onClick={navigateToTransfer} className="transfer-fund">{t('transfer')}</button>
          <button onClick={navigateToDeposit} className="cash-top-up">{t('deposit')}</button>
          {/* <button onClick={navigateToTransfer} className="transfer-fund">{t('transferFund')}</button> */}
        </div>
      </header>

      <section className="balance-info">
        <h3>{t('availableBalance')}:</h3>
        <p className="balance">{formatCurrencyWithCommas(Number(balance))}</p>
        <p className="account-info">{t('addMoneyInstructions')}</p>
        <div className="account-number-container">
          <p className="account-number">{user.accountNumber}</p>
          <button className="copy-button" onClick={() => copyToClipboard(user.accountNumber)}>
            <FaCopy />
          </button>
          {copySuccess && <span className="copy-success">{copySuccess}</span>}
        </div>
      </section>

      <section className="transaction-info">
        <div className="info-box">
          <h4>{t('transactionLimit')}</h4>
          <p>{formatCurrencyWithCommas(50000000)}</p>
        </div>
        <div className="info-box">
          <h4>{t('pendingTransaction')}</h4>
          <p>{formatCurrencyWithCommas(0)}</p>
        </div>
        <div className="info-box">
          <h4>{t('transactionVolume')}</h4>
          <p>{formatCurrencyWithCommas(volume || 0)}</p>
        </div>
      </section>

      <section className="recent-transactions">
        <h3>{t('recentTransactions')}</h3>
        {error && <p className="error-message">{error}</p>}
        <table>
          <thead>
            <tr>
              <th>{t('amount')}</th>
              <th>{t('type')}</th>
              <th>{t('referenceID')}</th>
              <th>{t('status')}</th>
              <th>{t('date')}</th>
              <th>{t('time')}</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index}>
                <td>{formatCurrencyWithCommas(transaction.amount)}</td>
                <td>{t(transaction.type)}</td>
                <td>{transaction.reference}</td>
                <td>{t(transaction.status)}</td>
                <td>{new Date(transaction.dateTime).toLocaleDateString(i18n.language)}</td>
                <td>{new Date(transaction.dateTime).toLocaleTimeString(i18n.language)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default authMiddleware(Dashboard);