import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/EditUser.css'
const EditUser = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${global.baseUrl}users/user/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await response.json();
                setUser(data.user);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Failed to load user data.');
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser(prevUser => ({
            ...prevUser,
            [name]: value
        }));
    };

    const handleArrayInputChange = (e, index) => {
        const { name, value } = e.target;
        setUser(prevUser => ({
            ...prevUser,
            [name]: prevUser[name].map((item, i) => i === index ? value : item)
        }));
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${global.baseUrl}users/user/${userId}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            });

            if (!response.ok) {
                throw new Error('Failed to update user data');
            }

            navigate('/admin');
        } catch (err) {
            console.error(err);
            setError('Failed to update user data.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className='edit-user-cont'>
            <h1>Edit User</h1>
            <form className='edit-user-form'>
                <div className='form-group'>
                    <label htmlFor='firstName'>First Name:</label>
                    <input type='text' id='firstName' name='firstName' value={user.firstName} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='middleName'>Middle Name:</label>
                    <input type='text' id='middleName' name='middleName' value={user.middleName} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='lastName'>Last Name:</label>
                    <input type='text' id='lastName' name='lastName' value={user.lastName} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='username'>Username:</label>
                    <input type='text' id='username' name='username' value={user.username} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='country'>Country:</label>
                    <input type='text' id='country' name='country' value={user.country[1]} onChange={(e) => handleArrayInputChange(e, 1)} />
                </div>
                <div className='form-group'>
                    <label htmlFor='state'>State:</label>
                    <input type='text' id='state' name='state' value={user.state[1]} onChange={(e) => handleArrayInputChange(e, 1)} />
                </div>
                <div className='form-group'>
                    <label htmlFor='city'>City:</label>
                    <input type='text' id='city' name='city' value={user.city[1]} onChange={(e) => handleArrayInputChange(e, 1)} />
                </div>
                <div className='form-group'>
                    <label htmlFor='address'>Address:</label>
                    <input type='text' id='address' name='address' value={user.address} onChange={handleInputChange} />
                </div>
                {/* <div className='form-group'>
                    <label htmlFor='dateOfBirth'>Date of Birth:</label>
                    <input type='date' id='dateOfBirth' name='dateOfBirth' value={user.dateOfBirth.split('T')[0]} onChange={handleInputChange} />
                </div> */}
                <div className='form-group'>
                    <label htmlFor='phoneNumber'>Phone Number:</label>
                    <input type='text' id='phoneNumber' name='phoneNumber' value={user.phoneNumber} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='email'>Email:</label>
                    <input type='email' id='email' name='email' value={user.email} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='typeOfEmployment'>Type of Employment:</label>
                    <input type='text' id='typeOfEmployment' name='typeOfEmployment' value={user.typeOfEmployment} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='annualIncomeRange'>Annual Income Range:</label>
                    <input type='text' id='annualIncomeRange' name='annualIncomeRange' value={user.annualIncomeRange} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='SSN_TIN'>SSN/TIN:</label>
                    <input type='text' id='SSN_TIN' name='SSN_TIN' value={user.SSN_TIN} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='accountType'>Account Type:</label>
                    <input type='text' id='accountType' name='accountType' value={user.accountType} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='accountCurrency'>Currency *</label>
                    <select
                        id='accountCurrency'
                        name='accountCurrency'
                        value={user.accountCurrency}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Select currency</option>
                        <option value="USD">$ - US Dollar</option>
                        <option value="EUR">€ - Euro</option>
                        <option value="GBP">£ - British Pound</option>
                        <option value="HKD">HK$ - Hong Kong Dollar</option>
                    </select>
                </div>
                <div className='form-group'>
                    <label htmlFor='twoFactorPIN'>Two Factor PIN:</label>
                    <input type='password' id='twoFactorPIN' name='twoFactorPIN' value={user.twoFactorPIN} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='balance'>Balance:</label>
                    <input type='number' id='balance' name='balance' value={user.balance} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='availableBalance'>Available Balance:</label>
                    <input type='number' id='availableBalance' name='availableBalance' value={user.availableBalance} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='accountNumber'>Account Number:</label>
                    <input type='text' id='accountNumber' name='accountNumber' value={user.accountNumber} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label>Approved:</label>
                    <input type='checkbox' id='approved' name='approved' checked={user.approved} onChange={(e) => handleInputChange({target: {name: 'approved', value: e.target.checked}})} />
                </div>
                <div className='form-group'>
                    <label>Admin:</label>
                    <input type='checkbox' id='admin' name='admin' checked={user.admin} onChange={(e) => handleInputChange({target: {name: 'admin', value: e.target.checked}})} />
                </div>
                <div className='form-group'>
                    <label>Email Verified:</label>
                    <input type='checkbox' id='email_verified' name='email_verified' checked={user.email_verified} onChange={(e) => handleInputChange({target: {name: 'email_verified', value: e.target.checked}})} />
                </div>
                <button type='button' className='save-button' onClick={handleSave}>Save</button>
            </form>
        </div>
    );
};

export default EditUser;