import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/ContactUs.css';
import bannerImg from '../images/banking.png';
import complaintPDF from '../images/Complaint_-_notice_to_customer_20240328.pdf';
import TopNav from './TopNav';

const BankServices = () => {
    const [activeContent, setActiveContent] = useState('DepositServices');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    const handleComplaintClick = () => {
        window.open(complaintPDF, '_blank');
    };

    const renderContent = () => {
        switch (activeContent) {
            case 'DepositServices':
                return (
                    <div className='DepositServices'>
                        <br />
                        <div className='firstDiv'>
                            <div className="HKD">
                                <div className="headerba">
                                    <h2>{t('HKD Current Account')}</h2>
                                </div>
                                <div className="bodyba">
                                    <ul>
                                        <br />
                                        <li>{t('Minimum amount of deposit to open an account in personal or joint names - HK$2,000.00')}</li>
                                        <br />
                                        <li>{t('Minimum amount of deposit to open an account in company name - HK$5,000.00')}</li>
                                        <br />
                                        <li>{t('Monthly statement provided showing details of transactions')}</li>
                                    </ul>
                                </div>
                            </div>
                            <br /><br />
                            <div>
                                <div className="headerba">
                                    <h2>{t('HKD Passbook Savings Account')}</h2>
                                </div>
                                <div className="bodyba">
                                    <ul>
                                        <br />
                                        <li>{t('Minimum amount of deposit to open an account - HK$100.00')}</li>
                                        <br />
                                        <li>{t('Minimum amount of deposit to earn interest - HK$10.00')}</li>
                                        <br />
                                        <li>{t('Interest payable semi-annually in June and December each year')}</li>
                                        <br />
                                        <li>{t('All transactions are recorded on passbook')}</li>
                                        <br />
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br /><br />
                        <div className="firstDiv">
                            <div className="HKD">
                                <div className="headerba">
                                    <h2>{t('RMB Statement Savings Account')}</h2>
                                </div>
                                <div className="bodyba">
                                    <br />
                                    <ul>
                                        <li>{t('Minimum amount of deposit to open an account - HK$100.00')}</li>
                                        <br />
                                        <li>{t('Minimum amount of deposit to earn interest - HK$10.00')}</li>
                                        <br />
                                        <li>{t('Interest payable semi-annually in June and December each year')}</li>
                                        <br />
                                        <li>{t('All transactions are recorded on passbook')}</li>
                                        <br />
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <div className="headerba">
                                    <h2>{t('Fixed Deposit')}</h2>
                                </div>
                                <div className="bodyba" id="FixedDeposit">
                                    <br />
                                    <ul>
                                        <li>{t('Minimum amount of deposit to open an account - HK$100.00')}</li>
                                        <br />
                                        <li>{t('Minimum amount of deposit to earn interest - HK$10.00')}</li>
                                        <br />
                                    </ul>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                );
            case 'Other':
                return (
                    <div className="directorsBankingServices">
                        <div className="headerba" id='bsheader'>
                            <h2>{t('Other Banking Services')}</h2>
                        </div>
                        <br />
                        <div className="bodyba" id='bsheader'>
                            <p>{t('1. Gift Cheque')}</p>
                            <br />
                            <p>{t('2. Cashier\'s Order')}</p>
                            <br />
                            <p>{t('3. Notes Exchanges')}</p>
                            <br />
                        </div>
                        <br /><br />
                    </div>
                )
            case 'loanServices':
                return (
                    <p className='loanEnq'>{t('For general enquiries / enquiries on Payment Arrangement for Property Transactions, please contact the Bank at info@taisbkhk.com')}</p>
                )
            default:
                return null;
        }
    }

    return (
        <div className="contactMainCont">
            <br />
            <TopNav />
            <br />
            <div className='banner'>
                <img id='bannerImg' src={bannerImg} alt='' />
            </div>
            <br />
            <div className='clickableItems'>
                <span
                    onClick={() => setActiveContent('DepositServices')}
                    className={activeContent === 'DepositServices' ? 'active' : ''}
                >
                    {t('Deposit Services')}
                </span>
                <span
                    onClick={() => setActiveContent('Other')}
                    className={activeContent === 'Other' ? 'active' : ''}
                >
                    {t('Other Banking Services')}
                </span>
                <br /><br />
                <span
                    onClick={() => setActiveContent('loanServices')}
                    className={activeContent === 'loanServices' ? 'active' : ''}
                >
                    {t('Loan Services')}
                </span>
                <br />
                <br />
            </div>
            <div className='content'>
                {renderContent()}
            </div>
            <br /><br />
        </div>
    );
}

export default BankServices;