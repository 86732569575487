import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../styles/safeDeposit.css';
import bannerImg from '../images/RPM.png';
import TopNav from './TopNav';

const Rental = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    const navigateToHome = () => {
        navigate('/');
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div>
            <br />
            <TopNav />
            <br />
            <div className='bannerImg'>
                <img id='bannerImg' src={bannerImg} alt="" />
            </div><br />
            <div className='safeContenti'>
                <div className='rpmHeader'>
                    <h3>{t('officesToLet')}</h3>
                </div>
                <div className='rpmBody'>
                    <div className='rpmBodyFlex'>
                        <div id='rpmtitle'>
                            <br />
                            <span>{t('address')}</span><br /><br />
                            <span>{t('area')}</span>
                        </div>
                        <div>
                            <br />
                            <span>{t('officeAddress')}</span><br /><br />
                            <span>{t('officeArea')}</span>
                        </div>
                    </div><br />
                    <div>{t('enquiryInstructions')}</div>
                </div>
            </div>
            <br /><br />
        </div>
    );
};

export default Rental;