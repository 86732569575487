import authMiddleware from './authMiddleware';
import React, { useState } from 'react';
import '../styles/ResetPassword.css'; // Make sure to create this CSS file

const ResetPassword = () => {
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Password reset submitted:', passwordData);
    // Here you would typically send the data to your backend
  };

  return (
    <div className="reset-password-container">
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          name="currentPassword"
          value={passwordData.currentPassword}
          onChange={handleChange}
          placeholder="Current Password"
        />
        <input
          type="password"
          name="newPassword"
          value={passwordData.newPassword}
          onChange={handleChange}
          placeholder="New Password"
        />
        <input
          type="password"
          name="confirmPassword"
          value={passwordData.confirmPassword}
          onChange={handleChange}
          placeholder="Confirm Password"
        />
        <button type="submit">Change Password</button>
      </form>
      <div className="password-requirements">
        <p>Password requirements</p>
        <p>Ensure that these requirements are met</p>
        <ul>
          <li>Minimum 8 characters long - the more, the better</li>
          <li>At least one lowercase character.</li>
          <li>At least one uppercase character.</li>
          <li>At least one number.</li>
        </ul>
      </div>
    </div>
  );
};

export default authMiddleware(ResetPassword)