import authMiddleware from './authMiddleware';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/DepositPage.css';

const DepositPage = () => {
  const { t } = useTranslation();
  const [depositMethod, setDepositMethod] = useState('');
  const [amount, setAmount] = useState('');
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const handleDeposit = (e) => {
    e.preventDefault();
    if (depositMethod === 'Crypto' || depositMethod === 'USDT') {
      setShowPaymentDetails(true);
    } else if (depositMethod === 'Bank Transfer') {
      alert(t('bankTransferDetails'));
    }
  };

  const CryptoPaymentDetails = () => (
    <div className="crypto-payment-details">
      <h2>{t('cryptoPayment')}</h2>
      <img src="/bitcoin-logo.png" alt={t('bitcoinLogo')} className="bitcoin-logo" />
      <img src="/qr-code-placeholder.png" alt={t('qrCodeAlt')} className="qr-code" />
      <p className="instruction">{t('sendMinimum', { amount })}</p>
      <div className="crypto-addresses">
        <p><strong>{t('bitcoin')}:</strong></p>
        <p className="address">bc1qsv4m57ldyxu3nfnn57v7y0utmp5wplu3vpy8m8</p>
        <p><strong>{t('usdtTrc20')}:</strong></p>
        <p className="address">TUQH6g13gFKPTj9rzyGUDPQpp47GQPCGvW</p>
        <p><strong>{t('usdtErc20')}:</strong></p>
        <p className="address">0xd94D0A4aE4F29bf408c48d8217fB53cb68a11Db9</p>
        <p><strong>{t('eth')}:</strong></p>
        <p className="address">0xd94D0A4aE4F29bf408c48d8217fB53cb68a11Db9</p>
      </div>
      <div className="transaction-details">
        <p><strong>{t('minimumToPay')}:</strong> {amount} USD</p>
        <p><strong>{t('transactions')}:</strong> {t('cryptocurrency')} 2</p>
        <p><strong>{t('status')}:</strong> {t('waitingPayment')}</p>
        <p><strong>{t('note')}:</strong> {t('accountFillNote')}</p>
      </div>
    </div>
  );

  return (
    <div className="deposit-page">
      <h1 className='h1'>{t('depositFunds')}</h1>
      <form onSubmit={handleDeposit}>
        <div className="form-group">
          <label htmlFor="depositMethod">{t('depositFrom')}</label>
          <select
            id="depositMethod"
            value={depositMethod}
            onChange={(e) => setDepositMethod(e.target.value)}
            required
          >
            <option value="">{t('selectDepositMethod')}</option>
            <option value="Crypto">{t('Crypto')}</option>
            {/* <option value="USDT">{t('usdt')}</option> */}
            <option value="Bank Transfer">{t('bankTransfer')}</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="amount">{t('amount')}</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder={t('enterAmount')}
            min="50"
            step="0.01"
            required
          />
        </div>
        <button type="submit" className="deposit-button">{t('deposit')}</button>
      </form>

      {showPaymentDetails && (
        <div className="modal">
          <div className="modal-content">
            <h2>{t('paymentDetails')}</h2>
            <CryptoPaymentDetails />
            <button onClick={() => setShowPaymentDetails(false)} className="go-back-button">{t('goBack')}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default authMiddleware(DepositPage)