import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png';
import menuIcon from '../images/menuIcon.png';

const TopNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setIsOpen(false);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const handleOnlineBanking = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  };

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <div className='topNav'>
      <img onClick={navigateToHome} id='logo' src={logo} alt='logo' />
      <div className='linksDiv'>
        <a href='/'>{t('home')}</a>
        <a href='/aboutUs'>{t('aboutUs')}</a>
        <a href='/bank/services'>{t('bankServices')}</a>
        <a href='/useful/information'>{t('usefulInformation')}</a>
        <a href='/contactUs'>{t('contactUs')}</a>
        <a href='/signup'>{t('signUp')}</a>
        <div className="languageDropdown">
          <button
            className="languageButton"
            onClick={() => setIsOpen(!isOpen)}
          >
            {i18n.language === 'en' ? 'English' : '中文'}
            <span className="dropdownArrow">▼</span>
          </button>
          {isOpen && (
            <div className="dropdownMenu">
              <button onClick={() => changeLanguage('en')}>English</button>
              <button onClick={() => changeLanguage('zh')}>中文</button>
            </div>
          )}
        </div>
      </div>
      <button onClick={handleOnlineBanking} id='online'>{t('onlineBanking')}</button>
      <img id='menuIcon' src={menuIcon} alt='Menu Icon' onClick={() => {}} />
    </div>
  );
};

export default TopNav;