import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CURRENCY_MAP = {
  USD: { symbol: '$', name: 'US Dollar' },
  EUR: { symbol: '€', name: 'Euro' },
  GBP: { symbol: '£', name: 'British Pound' },
  HKD: { symbol: 'HK$', name: 'Hong Kong Dollar' },
};

const useFetchUserData = (userId) => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  const fetchUserData = useCallback(async () => {
    if (!userId) return;

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${global.baseUrl}users/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const data = await response.json();
      setUserData(data);
    } catch (err) {
      setError(err.message);
    }
  }, [userId]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return { userData, error, refetch: fetchUserData };
};

const formatCurrency = (amount, currency, language) => {
  if (!currency || !CURRENCY_MAP[currency]) {
    return `${amount}`;
  }

  const { symbol } = CURRENCY_MAP[currency];
  return `${symbol}${amount.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

export const useCurrencyFormatter = () => {
  const [userId, setUserId] = useState(null);
  const { userData, error, refetch } = useFetchUserData(userId);
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.id) {
      setUserId(user.id);
    }
  }, []);

  const formatter = useCallback((amount) => {
    if (!userData || !userData.user || !userData.user.accountCurrency) {
      return `${amount}`;
    }
    return formatCurrency(amount, userData.user.accountCurrency, i18n.language);
  }, [userData, i18n.language]);

  return { 
    formatCurrency: formatter, 
    error, 
    refetchUserData: refetch,
    navigateToError: () => navigate('/error')
  };
};

export default useCurrencyFormatter;